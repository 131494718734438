<template>
  <div class="Chat-Bot">
    <div class="chat">
      <transition name="fade">
        <v-card
          v-show="isOpen"
          class="chat-box"
        >
          <v-card-title>
            <div class="header">
              <div class="d-flex align-center">
                <span class="img">
                  <!-- <v-img
                    src="@/assets/icons/glowing-orb.png"
                    width="50"
                  /> -->
                </span>
                <span class="title">ORKO AI</span>
              </div>
              <div
                class="close"
                @click="isOpen = !isOpen"
              >
                <v-icon> mdi-close </v-icon>
              </div>
            </div>
          </v-card-title>
          <v-card-text id="convo-body">
            <div class="conversation">
              <div
                v-for="(con, i) in list"
                :key="i"
                :class="con.type"
              >
                {{ con.msg }}
              </div>
            </div>
          </v-card-text>
          <v-card-text v-if="loading">
            <div class="loading">
              typing
            </div>
          </v-card-text>
          <v-card-actions>
            <div class="prompt-box">
              <v-chip-group mandatory>
                <v-chip
                  v-for="(tag, i) in prompt"
                  :key="i"
                  :disabled="loading"
                  @click="sendMsg(tag)"
                >
                  {{ tag.name }}
                </v-chip>
              </v-chip-group>
            </div>
          </v-card-actions>
        </v-card>
      </transition>
      <v-btn
        class="mx-2"
        dark
        icon
        large
        @click="isOpen = !isOpen"
      >
        <v-img
          v-if="!isOpen"
          contain
          src="@/assets/brandLogo/ai-button.png"
        />
        <v-icon v-else>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    prompt: {
      type: Array,
      default() {
        return [];
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
    }),
  },
  updated() {
    this.contentScrollBottom();
  },
  methods: {
    sendMsg(value) {
      const params = {
        prompt: value.id,
        promptLabel: value.name,
      };
      this.$emit("send", params);
    },
    contentScrollBottom() {
      setTimeout(() => {
        const x = document.getElementById("convo-body");
        const h = x.scrollHeight;
        x.scrollTo(0, h);
      }, 50);
    },
  },
};
</script>
<style lang="sass" scoped>
.Chat-Bot
    position: fixed
    z-index: 99999
    right: 5px
    bottom: 3rem
    .chat
        .chat-box
            display: flex
            flex-direction: column
            width: 50vw
            min-height: 90%
            background: #fff
            border-radius: 10px
            position: fixed
            z-index: 9
            // top: 0
            bottom: 0
            right: 0
            // transform: translate(-81%, -105%)
.header
  display: flex
  align-items: center
  justify-content: space-between
  flex: 1
  .close
    cursor: pointer


#convo-body
    display: flex
    flex-direction: column
    justify-content: space-between
    flex: 1
    padding-top: 5px
    border-top: 1px solid lightgray
    // border-bottom: 1px solid lightgray
    // max-height: 350px
    overflow: auto
    scroll-behavior: smooth !important

    .conversation
        display: flex
        flex-direction: column
        height: 300px

        .sent
            align-self: end !important
            background: #20a390 !important
            color: #fff
        .received
            align-self: start !important
            background: lightgray !important
        div
            // width: fit-content
            // max-width: 50% !important
            margin-bottom: 0.5rem
            font-size: 12px
            padding: 0.5rem 1rem
            border-radius: 1rem

.prompt-box
    display: flex
    gap: 5px
    width: -webkit-fill-available !important
    .v-chip
      transition: all 0.5s ease linear
      &:hover
        background: #20a39f !important
        color: #fff

.loading
    font-size: 9px
    text-transform: capitalize
    font-style: italic
    animation: content 0.5s ease linear
    &::after
        content: "."


:deep .v-card__actions
  border-top: 1px solid lightgray
.v-btn
    background-color: #1fb3a4
    width: 4.5rem
    height: 4.5rem
    border-radius: 100%
    :deep .v-image
      width: 20px
      border-radius: 100%
    // border-top-left-radius: 50px
    // border-bottom-left-radius: 50px
    transition: all 0.2s ease-in-out 0.2s
    // // -webkit-animation: blink 0.7s infinite alternate
    // // animation: blink 0.7s infinite alternate
    &:hover
      box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px #20a39f, 0 0 15px #20a39f, 0 0 20px #20a39f, 0 0 50px #20a39f
      width: 6rem
    // .v-image
    //     width: 20px
    //     height: 100%

// @keyframes blink
//     100%
        // box-shadow: 0 0 3px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #20a39f,0 0 70px #20a39f, 0 0 80px #20a39f


.fade-enter-active, .fade-leave-active
    transition: opacity 0.5s
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
    opacity: 0
</style>
<style scoped>
/* .v-btn::after {
    content: " ";
    width: 110%;
    height: 110%;
    border-radius: 100%;
    position: absolute;
    background: linear-gradient(#66fffa, #c6fffd 43%, #20a39f);
    box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 20px #20a39f, 0 0 30px #20a39f,
      0 0 40px #20a39f, 0 0 50px #20a39f;
  
    z-index: -1;
    transition: box-shadow 0.5s;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
      box-shadow: 0 0 3px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #20a39f,
        0 0 70px #20a39f, 0 0 80px #20a39f;
    }
  } */
</style>
